export default {
  menuHome: {
    id: 'menu.home',
    defaultMessage: 'Home',
  },
  menuStory: {
    id: 'menu.story',
    defaultMessage: 'Winery',
  },
  menuLocation: {
    id: 'menu.location',
    defaultMessage: 'Location',
  },
  menuWines: {
    id: 'menu.wines',
    defaultMessage: 'Wines',
  },
  menuContact: {
    id: 'menu.contact',
    defaultMessage: 'Contact',
  },
  menuLanguageEnglish: {
    id: 'menu.language.english',
    defaultMessage: 'English',
  },
  menuLanguageGreek: {
    id: 'menu.language.greek',
    defaultMessage: 'Greek',
  },
  footerGoogleMap: {
    id: 'footer.google.maps',
    defaultMessage: 'Google Maps Location',
  },
  footerContactInformation: {
    id: 'footer.contact.information',
    defaultMessage: 'Contact Information',
  },
};
